import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Quote from '../components/sections/quote';
import Awards from '../components/sections/awards';
import Body from '../components/sections/body';
import SEO from '../components/seo';

const Team = ({ data }) => {
  const page = data.sanityTeam;
  const heroImage = data.sanityAboutPage;
  return (
    <Layout heroHeading="Our Team" heroImage={heroImage.image}>
      <SEO title={page.fullname == null ? '' : page.fullname} />
      <Body
        id="bio"
        heading={page.fullname == null ? '' : page.fullname}
        subheading={page.title}
        content={page._rawBio}
        image={page.image}
      />

      {/* Awards */}
      {page.awardSection && <Awards awards={page.awardSection} />}
      {/* Quote */}
      {page.quoteToggle && <Quote />}
    </Layout>
  );
};

export default Team;

export const query = graphql`
  query teamQuery($id: String) {
    sanityTeam(id: { eq: $id }) {
      fullname
      title
      _rawBio
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      awardSection {
        awardList {
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      quoteToggle
    }
    sanityAboutPage {
      name
      heading
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
