import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Typography, Grid } from '@material-ui/core';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const OuterContainer = styled.div`
  position: relative;
  left: -2.5%;
  background-color: ${props => props.theme.palette.primary.main};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  width: 105%;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  border-top-left-radius: 50% 2rem;
  border-top-right-radius: 50% 2rem;
  margin-top: -15px;
  overflow: hidden;
`;

const InnerContainer = styled(BackgroundImage)`
  background-size: 300px 300px;
  background-repeat: repeat;
  background: white;
  /* left: -2.5%; */
  width: 105%;
  border-top-left-radius: 50% 2.5rem;
  border-top-right-radius: 50% 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &::before,
  &::after {
    background-size: 300px 300px;
    background-repeat: repeat;
    background: white;
    /* left: -2.5%; */
    width: 105%;
    border-top-left-radius: 50% 2.5rem;
    border-top-right-radius: 50% 2.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const Overlay = styled(Grid)`
  padding: 4rem 2rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.925);
  border-top-left-radius: 50% 2.5rem;
  border-top-right-radius: 50% 2.5rem;
`;

const Image = styled(Img)`
  margin: 0 auto;
  width: 300px;
`;

const QuoteText = styled(Typography)`
  max-width: 1000px;
`;

const Signature = styled(Typography)`
  padding-top: 2rem;
  transform: rotate(-5deg);
  font-family: 'Pacifico', cursive;
`;

const Quote = () => {
  const pageData = useStaticQuery(graphql`
    {
      sanityQuote {
        quote
        signature
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        backgroundImage {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <Image fluid={pageData.sanityQuote.image.asset.fluid} />
      <OuterContainer>
        <InnerContainer
          fluid={pageData.sanityQuote.backgroundImage.asset.fluid}
          alt="Animals background pattern"
        >
          <Overlay
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <QuoteText variant="h5" gutterBottom align="center">
              {/* <FaQuoteLeft color="#283891" /> */}
              {pageData.sanityQuote.quote}
              {/* <FaQuoteRight color="#283891" /> */}
            </QuoteText>
            <Signature variant="h3">{pageData.sanityQuote.signature}</Signature>
          </Overlay>
        </InnerContainer>
      </OuterContainer>
    </React.Fragment>
  );
};
export default Quote;
